@import ../../../styles/helpers

.container
    display: flex
    align-items: center
    max-width: 100%
    padding: 0
    +m
        display: block
.hero
  display: flex
  justify-content: center
  align-items: center
  padding-top:100px
  height: auto
  text-align: center // Centers the text if needed


  

.col
    display: flex
    flex-direction: column
    align-items: center // This ensures that the content within `.col` is also centered
    flex: 0 0 50%
    &:first-child
        +d
            flex: 0 0 40%
        +t
            flex: 0 0 45%
    &:nth-child(2)
        padding: 0 64px 0 106px
        +x
            padding: 0 40px 0 80px
        +d
            flex: 0 0 60%
        +t
            flex: 0 0 55%
            padding: 0 40px 0 40px
        +m
            padding: 48px 32px 64px

.wrap
    max-width: 450px

.stage
    margin-bottom: 21px

.title
    position: relative
    margin-bottom: 35px
    padding-bottom: 36px
    +m
        margin-bottom: 32px
        padding-bottom: 32px
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        width: 128px
        height: 1px
        background: $neutrals6
        +dark
            background: $neutrals3

.text
    margin-bottom: 40px
    color: $neutrals4
    +m
        margin-bottom: 32px

.subscription
    max-width: 300px

.preview
    +rmin(1800)
        height: 880px
    img
        width: 100%
        max-height: 100%
        object-fit: cover

