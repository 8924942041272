@import ../../../styles/helpers

.hero
    position: relative
    display: flex
    align-items: center
    min-height: 880px
    padding: 34px 0 180px
    +d
        min-height: 606px
        padding: 80px 0
    +m
        min-height: auto
        padding: 64px 0 0

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 32px

.purpleButton 
    background-color: #F97316 // Use the specific purple shade you want
    border: none
    color: #FFF // Adjust text color if needed
    
    &:hover 
        background-color: darken(black, 10%) // Optional: darken on hover
    
.title
    margin-bottom: 20px

.text
    margin-bottom: 40px
    color: $neutrals4

.btns
    display: flex

.button
    +m
        padding: 0 22px
    &:not(:last-child)
        margin-right: 16px
    
.scroll
    position: absolute
    bottom: 96px
    +d
        display: none

.gallery
    display: flex
    align-items: start // Or `center` based on your design needs
    justify-content: flex-start
    position: absolute
    top: 50%
    right: calc(50% - 720px)
    transform: translateY(-50%)
    pointer-events: none

.preview
    margin-right: 200px

    img
        width: 300px

    @media (max-width: 768px) 
        display: none // Hides the "Watch" image on screens smaller than 768px wide
